import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Shimmer from "./Shimmer";
import axios from "axios";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
// import { event } from "react-ga";

const Calculator = (props) => {
  // console.log('props oif Calculator',props?.body?.body);
  const rate = {
    background: `url("/image/rate_calculatoe_bg.png")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100%",
    // height: "100vh",
  };
  const rate2 = {
    background: `url("/image/rate_calculatoe_bg_inside.png")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100%",
    // height: "80vh",
  };
  const navigate = useNavigate();

  const [rateData, setRateData] = useState([]);

  const [originData, setOriginData] = useState(props?.body?.body?.origin ?? "");
  const [destinationData, setDestinationData] = useState(
    props?.body?.body?.destination ?? ""
  );
  const [invoicevalueData, setInvoicevalueData] = useState(
    props?.body?.body?.invoice_value ?? ""
  );
  const [weightData, setWeightData] = useState(props?.body?.body?.weight ?? "");
  const [modeData, setModeData] = useState(props?.body?.body?.mode ?? "");
  const [qtyData, setQtyData] = useState(props.body?.body?.qty ?? [""]);
  const [widthData, setWidthData] = useState(props.body?.body?.width ?? [""]);
  const [lengthData, setLengthData] = useState(
    props.body?.body?.length ?? [""]
  );

  const [heightData, setHeightData] = useState(
    props.body?.body?.height ?? [""]
  );

  const [Error, setError] = useState(null);
  const [orginError, setOrginError] = useState(false);
  const [destinationError, setDestinationError] = useState(false);
  const [WeightError, setWeightError] = useState(false);
  const [InvoiceError, setInvoiceError] = useState(false);
  const [LengthError, setLengthError] = useState(false);
  const [QtyError, setQtyError] = useState(false);
  const [widthError, setWidthError] = useState(false);
  const [hightError, setHightError] = useState(false);
  const [loading, setLoading] = useState(false);

  // console.log("inpute Value", input);
  useEffect(() => {
    OriginValidation(originData);
    DestinationValidation(destinationData);
    WeightValidation(weightData);
    InvoiceValidation(invoicevalueData);
    LengthValidation(lengthData);
    qtyValidation(qtyData);
    WidthValidation(widthData);
    HightValidation(heightData);
  }, [
    originData,
    destinationData,
    weightData,
    invoicevalueData,
    lengthData,
    qtyData,
    widthData,
    heightData,
  ]);

  const OriginValidation = (originData) => {
    if (originData.trim() === "") {
      setOrginError(null);
    } else if (originData.length < 6) {
      setOrginError("Enter six digit origin pincode");
    } else if (originData.length === 6 && /^[0-9]{6}$/.test(originData)) {
      setOrginError(null);
    } else {
      setOrginError("Invalid Origin Pincode");
    }
  };

  const DestinationValidation = (destinationData) => {
    if (destinationData.trim() === "") {
      setDestinationError(null);
    } else if (destinationData.length < 6) {
      setDestinationError("Enter six digit destination pincode");
    } else if (
      destinationData.length === 6 &&
      /^[0-9]{6}$/.test(destinationData)
    ) {
      setDestinationError(null);
    } else {
      setDestinationError("Invalid destination Pincode");
    }
  };
  const WeightValidation = (weightData) => {
    if (weightData.trim() === "") {
      setWeightError(null);
    } else if (weightData === "0") {
      setWeightError("Weight should be greater 0 kg");
    } else {
      setWeightError(null);
    }
  };
  const InvoiceValidation = (invoicevalueData) => {
    if (invoicevalueData.trim() === "") {
      setInvoiceError(null);
    } else if (invoicevalueData === "0") {
      setInvoiceError("Invoice value should be greater 0 Rupees");
    } else {
      setInvoiceError(null);
    }
  };
  const LengthValidation = (lengthData) => {
    if (lengthData === "") {
      setLengthError(null);
    } else if (lengthData == "0") {
      setLengthError("Please Enter Valid Length!");
    } else {
      setLengthError(null);
    }
  };
  const qtyValidation = (qtyData) => {
    if (qtyData === "") {
      setQtyError(null);
    } else if (qtyData == "0") {
      setQtyError("Please Enter Valid Qty!");
    } else {
      setQtyError(null);
    }
  };
  const WidthValidation = (widthData) => {
    if (widthData === "") {
      setWidthError(null);
    } else if (widthData == "0") {
      setWidthError("Please Enter Valid Width!");
    } else {
      setWidthError(null);
    }
  };
  const HightValidation = (heightData) => {
    if (heightData === "") {
      setHightError(null);
    } else if (heightData == "0") {
      setHightError("Please Enter Valid Height!");
    } else {
      setHightError(null);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      orginError ||
      destinationError ||
      WeightError ||
      InvoiceError ||
      QtyError ||
      widthError ||
      hightError ||
      LengthError
    ) {
      return;
    }
    setLoading(true);
    let url = "https://b2b.truxcargo.com/api/rate";
    let body = {
      origin: originData,
      destination: destinationData,
      invoice_value: invoicevalueData,
      mode: modeData,
      weight: weightData,
      qty: qtyData,
      width: widthData,
      length: lengthData,
      height: heightData,
      login_id: "",
    };
    try {
      const response = await axios.post(url, body);
      // console.log("rate Details", body);
      // console.log("rate response", response.data);
      setRateData(response.data.data);
      setLoading(false);

      navigate("/shipping_rate_details", {
        state: { rateData: response.data.data, body },
      });
      // console.log("navigate", response.data.data);
      // console.log("body", body);
    } catch (error) {
      setError("error");
      setLoading(false);
    }
  };

  const resetAllFileds = (event) => {
    event.preventDefault();
    setOriginData("");
    setDestinationData("");
    setInvoicevalueData("");
    setWeightData("");
    setModeData("");
    setQtyData([""]);
    setLengthData([""]);
    setWidthData([""]);
    setHeightData([""]);
  };

  const handleAddItem = (event) => {
    event.preventDefault();
    setQtyData((prevQty) => [...prevQty, ""]);
    setLengthData((prevLength) => [...prevLength, ""]);
    setWidthData((prevWidth) => [...prevWidth, ""]);
    setHeightData((prevHeight) => [...prevHeight, ""]);
  };

  const handleRemoveItem = (index) => {
    setQtyData((prevQty) => {
      const newQty = [...prevQty];
      newQty.splice(index, 1);
      return newQty;
    });
    setLengthData((prevLength) => {
      const newLength = [...prevLength];
      newLength.splice(index, 1);
      return newLength;
    });
    setWidthData((prevWidth) => {
      const newWidth = [...prevWidth];
      newWidth.splice(index, 1);
      return newWidth;
    });
    setHeightData((prevHeight) => {
      const newHeight = [...prevHeight];
      newHeight.splice(index, 1);
      return newHeight;
    });
  };

  return (
    <>
      <div className="calculator_section" style={rate}>
        <Form onSubmit={handleSubmit} style={rate2}>
          <div className="form_calcutor">
            <Form.Group className="w-100">
              <Form.Label>Origin Pincode</Form.Label>
              <Form.Control
                required
                pattern="\d{6}"
                minLength="6"
                maxLength="6"
                // maxLength={6}
                type="tel"
                placeholder="Enter Origin Pincode"
                name="origin"
                value={originData}
                onChange={(e) => setOriginData(e.target.value)}
              />
              {orginError && <p className="error-message">{orginError}</p>}
            </Form.Group>

            <Form.Group className="w-100 ">
              <Form.Label>Destination Pincode</Form.Label>
              <Form.Control
                required
                pattern="\d{6}"
                minLength="6"
                maxLength="6"
                type="tel"
                name="destination"
                placeholder="Enter Destination Pincode"
                // maxLength={6}
                value={destinationData}
                onChange={(e) => setDestinationData(e.target.value)}
              />
              {destinationError && (
                <p className="error-message">{destinationError}</p>
              )}
            </Form.Group>
          </div>
          <div className="form_calcutor">
            <Form.Group className="w-100">
            <Form.Label>Payment Mode</Form.Label>

              <FormControl sx={{ minWidth: "100%" }} size="small">
                <Select
                    required
                    name="mode"
                    value={modeData}
                    onChange={(e) => setModeData(e.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}

                  sx={{
                    '.MuiSelect-select': {
                      // padding: '3px 6px',
                      border: 'none',
                      // color: '#7939cc',
                      background: "#fff",
                      lineHeight: "1.7"
                    },
                    '.MuiSelect-icon': {
                      fontSize: '1.5rem',
                      color: '#ffffff',
                      background: 'linear-gradient(180deg, #7939CC 0%, #4656A3 100%)',
                      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                      borderRadius: '2px',
                    },
                    // ".MuiOutlinedInput-notchedOutline":{
                    //   background: "#fff"

                    // }
                  }}
                  >
                  <MenuItem value="">
                    Select Payment Mode
                  </MenuItem>
                  <MenuItem value="Prepaid">Prepaid</MenuItem>
                  <MenuItem value="COD">COD</MenuItem>
                  <MenuItem value="To Pay">To Pay</MenuItem>
                  <MenuItem value="Franchise-ToPay">Franchise-ToPay</MenuItem>
                </Select>
              </FormControl>
              {/* <Form.Select
                required
                name="mode"
                value={modeData}
                onChange={(e) => setModeData(e.target.value)}
              >
                <option value="" className="select_payment">
                  Select Mode
                </option>
                <option value="Prepaid">Prepaid</option>
                <option value="COD">COD</option>
                <option value="To-Pay">To Pay</option>
                <option value="Franchise-ToPay">Franchise-ToPay</option>
              </Form.Select> */}
            </Form.Group>

            <Form.Group className="w-100">
              <Form.Label>Approx Weight(Kg)</Form.Label>
              <Form.Control
                required
                type="number"
                name="weight"
                // min="0"
                // step="1"
                placeholder="Enter Approx Weight"
                value={weightData}
                onChange={(e) => setWeightData(e.target.value)}
              />
              {WeightError && <p className="error-message">{WeightError}</p>}
            </Form.Group>
          </div>
          <div className="form_calcutor">
            <Form.Group className="w-100">
              <Form.Label>Invoice Value (Rs)</Form.Label>
              <Form.Control
                required
                type="number"
                name="invoice_value"
                // min="0"
                // step="1"
                placeholder="Enter Invoice Value"
                value={invoicevalueData}
                onChange={(e) => setInvoicevalueData(e.target.value)}
              />
              {InvoiceError && <p className="error-message">{InvoiceError}</p>}
            </Form.Group>
          </div>

          <div></div>

          <div className="dimension_part ">
            <div className="d-flex justify-content-between mb-3">
              <h6>Dimensions (cm)</h6>

              {qtyData != "" &&
                widthData != "" &&
                lengthData != "" &&
                heightData != "" && (
                  <button
                    className="btn button-add-item"
                    onClick={handleAddItem}
                  >
                    Add Item
                  </button>
                )}
            </div>

            {qtyData.map((item, index) => (
             
                <div className="form_calcutor" key={index}>
                  <Form.Group className="w-100">
                    <Form.Label>Qty</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      name="qty"
                      // min="0"
                      // step="1"
                      value={qtyData[index]}
                      placeholder="Qty"
                      onChange={(e) =>
                        setQtyData((preQty) => {
                          const newQty = [...preQty];
                          newQty[index] = e.target.value;
                          return newQty;
                        })
                      }
                    />
                    {QtyError && <p className="error-message">{QtyError}</p>}
                  </Form.Group>

                  <Form.Group className="w-100">
                    <Form.Label>Length</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="L"
                      name="length"
                      // min="0"
                      // step="1"
                      value={lengthData[index]}
                      onChange={(e) =>
                        setLengthData((preLength) => {
                          const newLength = [...preLength];
                          newLength[index] = e.target.value;
                          return newLength;
                        })
                      }
                    />
                    {LengthError && (
                      <p className="error-message">{LengthError}</p>
                    )}
                  </Form.Group>

                  <Form.Group className="w-100">
                    <Form.Label>Width</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="W"
                      name="width"
                      // min="0"
                      //  step="1"
                      value={widthData[index]}
                      onChange={(e) =>
                        setWidthData((preWidth) => {
                          const newWidth = [...preWidth];
                          newWidth[index] = e.target.value;
                          return newWidth;
                        })
                      }
                    />
                    {widthError && (
                      <p className="error-message">{widthError}</p>
                    )}
                  </Form.Group>

                  <Form.Group className="w-100">
                    <Form.Label>Height</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="H"
                      name="height"
                      // min="0"
                      // step="1"
                      value={heightData[index]}
                      onChange={(e) =>
                        setHeightData((preHeight) => {
                          const newHeight = [...preHeight];
                          newHeight[index] = e.target.value;
                          return newHeight;
                        })
                      }
                    />
                    {hightError && (
                      <p className="error-message">{hightError}</p>
                    )}
                  </Form.Group>

                  {index != 0 && (
                    <div>
                      <i
                        onClick={() => handleRemoveItem(index)}
                        className="fa-solid fa-circle-xmark"
                      ></i>
                    </div>
                  )}
                </div>
             
            ))}
            {/* <div className="text-center dimension-center">
              <img src="/image/box_dimension.png" alt="" srcset="" />
              <p className="userInputLength">{lengthData[0]}</p>
              <p className="userInputHeight">{heightData[0]}</p>
              <p className="userInputWidth">{widthData[0]}</p>
            </div> */}
          </div>

      
          <div className="cal_buttons">
          
          
            <button type="submit" className="btn button-cal">
            {loading ? <span class="loader"></span>
            :
            <span>Calculate</span>
          }
            
            </button>

            <button
              onClick={resetAllFileds}
              className="btn btn-outline-primary button-reset"
            >
              Reset
            </button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Calculator;
