import React, { useEffect } from "react";
import Navigation from "../components/Navigation";
import Calculator from "../components/Calculator";
import NewsLetter from "../components/NewsLetter";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet";

const ShippingRateCalculator = ({updateMetaTags}) => {
  useEffect(() => {
    updateMetaTags("Door to door delivery to india | Multiple Pickup | Truxcargo",
    "Discover the convenience of Truxcargo's Shipping Rate Calculator, ensuring cost-effective shipping solutions for your business. Click here for more information.",
    "Shipping Rate Calculator, Rates of courier services in india, Charges of courier services in india, 1 kg courier charges in india");
  }, []);
  const bg_early = {
    borderRadius: "30px",
   background : `url("/image/solution_folder/ratecalculatar/bgstage.png")`,
    padding: "6rem",
    position: "relative",
    color: "#fff",
    overflow: "hidden",
    backgroundPosition: "center",
    backgroundSize: "cover",
  };
  const newsletterProps = {
    background : `url("/image/solution_folder/ratecalculatar/newsletter.png") center center / cover no-repeat`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    content: "Stay Connected for Rate Calculator Updates ",
    paragraph: "Stay informed about all developments and product updates at Truxcargo"
    // backgroundImage,
    // content: 'Subscribe to our newsletter for the latest updates!',
  };
  const newsletterRateStyle = {
   color: "#fff",
  };
  return (
    <>
     <Helmet>
      <title>Shipping Rate Calculator | Truxcargo</title>
     
    </Helmet>
      <Navigation />

      <div className="inner-banner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-12 step-part">
              <div className="service_top_content primum-steps">
                <h4>Shipping Rate Calculator</h4>
                <p className="text-white">
                Your Path to Cost Savings, Precision, and Business Success in Every Shipment. <br/> Ship Smart with Confidence!
                </p>
                <ScrollLink to="rate_stages"><button class="btn btn-outline-light ">Explore</button></ScrollLink>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-12">
              <div className="text-end">
                <img
                  src="/image/solution_folder/ratecalculatar/1.png"
                  alt=""
                  srcSet=""
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid margin_top_bottom">
        <div className="ndr-mangement-section ">
          <div className="shiping-rate_cal">
            <h2  style={{color: "#7939CC"}}>Shipping Cost Estimator</h2>

            <p className="shiping_main_content">
            The Shipping Rate Calculator is an essential tool for individuals and businesses engaged in shipping. It simplifies the complex task of rate calculation, providing highly accurate estimates of shipping costs. Whether shipping domestically or internationally, it offers real-time insights into pricing structures and carrier options. This tool not only saves time but also ensures cost-effective choices for shipments. With the Shipping Rate Calculator, you can streamline logistics, optimize your budget, and ship with confidence, securing the best value for your money.
            </p>
            <div className="row justify-content-end">
            <div className=" col-lg-7 col-md-12">
            <div >
                <Calculator />
               <div className="text-end">

               <img src="/image/solution_folder/ratecalculatar/3.png" alt="" srcset="" className="w-25" />
               </div>
              </div>
            </div>
          </div>
          </div>
         
        </div>
      </div>

      <div className="container-fluid ">
        <div className="cod-connect margin_top_bottom"> 
          <div className="inside_connect step-part">
          <div className="primum-steps">
            <p>Feel free to reach out to our experts for a comprehensive overview of the Rate Calculator</p>
           <RouterLink to="/contactus"> <button className="btn btn-outline-light">Connect Now</button></RouterLink>
          </div>

          <div className="text-end">
            <img
              src="/image/solution_folder/ratecalculatar/5.png"
              alt=""
              srcset=""
             
            />
          </div>
          </div>
        </div>
      </div>


      <div id="rate_stages" className="container-fluid margin_top_bottom">
        <div className="rate_cal_stages" style={bg_early}>
          <h1>Key Determinants of Shipping Costs</h1>
         
          <div className="row justify-content-center mt-5">
            <div className="col-md-12">
              <div className="early-numbers">
                <div>
                  <h5>1</h5>
                </div>
                <div className="number_content">
                  <h4>Distance as a Rate Determinant</h4>
                  <p>
                  One of the primary factors influencing shipping rates is the distance between the origin and destination. Shipping costs tend to increase with greater distances, reflecting higher transportation and handling expenses. Understanding the implications of distance is essential for making informed shipping choices, especially for businesses seeking to optimize their logistics and cost efficiency.


                  </p>
                </div>
              </div>

              
            </div>
            <div className="col-lg-7 early-numbers_pic">
                <img
                   src="/image/solution_folder/ratecalculatar/6.png"
                  alt=""
                  srcset=""
                 
                />
              </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-lg-12">
              <div className="early-numbers">
                <div>
                  <h5>2</h5>
                </div>
                <div className="number_content">
                  <h4>Parcel Weight and Rate Calculation</h4>
                  <p>
                  Parcel weight is a critical determinant of shipping costs. Heavier packages typically result in higher rates, as they demand more resources, including fuel and handling. Precise weight measurement is vital for accurate rate calculations. By comprehending the impact of parcel weight, shippers can make cost-effective decisions and ensure efficient budget management.
                  </p>
                </div>
              </div>

              
            </div>
            <div className="col-lg-7">
                <img
                  src="/image/solution_folder/ratecalculatar/8.png"
                  alt=""
                  srcset=""
                  className="w-100"
                 
                />
              </div>
          </div>
          <div className="row justify-content-center  mt-5">
            <div className="col-lg-12">
              <div className="early-numbers">
                <div>
                  <h5>3</h5>
                </div>
                <div className="number_content">
                  <h4>Shipping Type and Its Impact on Rates</h4>
                  <p>
                  The chosen shipping type is a pivotal factor in rate calculation. Whether it's standard, expedited, or specialized shipping, the type of service selected influences the cost. Expedited services, for instance, often come with higher rates due to faster delivery times. Understanding how different shipping types affect rates is crucial for optimizing shipping strategies and managing expenses effectively.


                  </p>
                </div>
              </div>

           
            </div>
            <div className="col-lg-7">
                <img
                    src="/image/solution_folder/ratecalculatar/7.png"
                  alt=""
                  srcset=""
                  className="w-100"
                />
              </div>
          </div>
          
        </div>
      </div>
    <section className="weight_newsletter">
    <div style={newsletterRateStyle}>
     <NewsLetter {...newsletterProps} />
     </div>
    </section>
    </>
  );
};

export default ShippingRateCalculator;
