import React from 'react'
import { Helmet } from "react-helmet";
import Navigation from '../components/Navigation';
import Tracking from "../components/Tracking";
import { Link } from 'react-router-dom';
const Track = () => {
    const track = {
        background: `url("/image/track/bg_1.png")`,
        width: "100%",
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        // height: "85vh",
        padding: "7% 0%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
     
      };
    const map = {
        background: `url("/image/track/pattern2.png")`,
        width: "100%",
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        // height: "100vh",
        padding: "2rem 0",
        display: "flex",
        // justifyContent: "center",
        // flexDirection: "column",
        // alignItems: "center",
     
      };

      const trusted = {
        background: `url("/image/track/connect.png")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        borderRadius: "30px",
        backgroundRepeat: "no-repeat",
      };
    
  return (
<>
<Helmet>
    <title>Tracking Orders</title>
    <meta
      name="description"
      content="Explore our Frequently Asked Questions to gain insights into Truxcargo’s services. Find answers to commonly raised queries about our logistics solutions."
    />
    <meta name="keywords" content="Indian courier services" />
  </Helmet>
  <Navigation />

  <section className="contact_section track_section" style={track}>
    <div className="container-fluid">
      <div className="row">
        <div className=" col-lg-6 col-md-8 col-12 step-part">
          <div className="left-side primum-steps">
            <h4 className='mb-4'>Track Your Order</h4>
           <Tracking/>
          </div>
        </div>
        <div className="col-lg-6 col-md-4 col-12">
          <div className="right_side" style={{visibility: "hidden"}}>
            <img
              src="/image/faq/1.png"
              alt=""
              srcset=""
              className="w-100"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

<section className='map_shipping_track' style={map}>
<div className='container-fluid'>
    <div className="row">
        <div className="col-md-6">
            <div>
                <h4>Shipping Partners</h4>
                <p>Bringing a multitude of shipping courier together onto one unified platform, "Multiple courier on a Single Platform" revolutionizes the logistics game. This innovative concept eliminates the complexity of managing individual courier relationships, offering an all-in-one solution for businesses and individuals.
</p>
<p>With a diverse array of courier at your fingertips, you gain unparalleled flexibility in choosing the right partner for your unique shipping needs.</p>
            </div>
        </div>
        <div className="col-md-6">
            <div>
                <img src="/image/track/map_shipping.png" className='w-100' alt="" srcset="" />
            </div>
        </div>
    </div>

  </div>
</section>


<section style={{backgroundColor: "#D7C4F0", padding: "3rem 0"}}>
    <div className='container-fluid'>
        <div className="row">
            <div className="col-md-6">
                <div>
                    <img src="/image/track/intregation_1.png" className='w-75' alt="" srcset="" />
                </div>
            </div>
            <div className="col-md-6">
            <div className="integration_picture  ">
            <h4>Ecommerce Integrations</h4>
              </div>
              <div className="integration_picture_magento ">
             
                <p>Elevate Your Ecommerce Game: Seamlessly Sync Multiple Channel Orders with TRUXCARGO's Dashboard. Say Goodbye to Coding Hassles and Unlock a World of Powerful Insights at Your Fingertips.</p>
                <ul>
                  <li><b>Elevate Ecommerce Efforts:</b> <p>TRUXCARGO's Magic Dashboard empowers ecommerce businesses effortlessly.</p></li>
                  <li><b>Simplified Order Synchronization:</b> <p>No coding complexities; synchronize multiple channel orders seamlessly.</p></li>
                  <li><b>User-Friendly Integration:</b><p> Transform your website into a well-coordinated hub, merging data from various sources.</p></li>
                  <li><b>Insights at Your Fingertips:</b> <p>The dashboard provides invaluable insights for smarter decision-making.</p></li>
                  <li><b>Effortless Integration:</b><p> Bid farewell to manual integration hassles with TRUXCARGO.</p></li>
                  <li><b>Enhanced Efficiency: </b><p>Enjoy a smarter and more efficient ecommerce experience.</p></li>
                  <li><b>Magic in Operations:</b> <p>TRUXCARGO adds magic to your online business operations.</p></li>
                
                </ul>
                {/* <Link to="/"><Button className="btn button-reg mt-5">Explore</Button></Link> */}

              </div>
            </div>
        </div>

    </div>
</section>
<div className="container-fluid margin_top_bottom">
        <div className="shiping_now" style={trusted}>
          <div className="row justify-content-center">
            <div className="col-md-8 col-11 ">
              <div className="shiping_now_inside">
                <h1>Start your Shipping Today</h1>
                <p>
                Elevate Your Shipping Experience with Seamless Solutions and Expertise.
                </p>

                <Link to="/contactus">
                  <button className="btn btn-light ">
                  Connect Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
</>
  )

}

export default Track