

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

const RootComponent = () =>{
  
  return <App />
}
root.render (<RootComponent/>);

